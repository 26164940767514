import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import CopyRight from '@/common/CopyRight';
import styles from './index.module.less';

const LOCAL_STORAGE_KEY = 'si-deprecated-is-clicked';

const Footer = () => {
  const isClicked = !!localStorage.getItem(LOCAL_STORAGE_KEY);
  const [isModalOpen, setIsModalOpen] = useState(!isClicked);

  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
  };

  return (
    <footer className={styles.footer}>
      <CopyRight />
      <Modal footer={null} title="Disclaimer" visible={isModalOpen} onCancel={handleOk}>
        <p>
          Due to X&apos;s (formerly Twitter) policies and terms, Conviva cannot guarantee the
          continued availability of X data or data feeds as part of the Social Insights services.
          Access to X data and feeds will be discontinued before September 30th, 2024.
        </p>
        <div style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleOk}>
            OK
          </Button>
        </div>
      </Modal>
    </footer>
  );
};

export default React.memo(Footer);
